'use strict';

angular.module('kljDigitalLibraryApp')
    .service('MainService', ["$q", "common", "appConfig", function($q, common, appConfig) {

        var url = "/api/subscription/getSubscriptionsByCategory";


        this.getSubscriptions = function(payload) {

            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                payload)

            .then(function(response) {

                return response.data;
            })
        }

        this.getList = function() {

            return common.callApi('GET',
                "/api/common/getList",
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE)

            .then(function(response) {

                return response.data;
            })
        }

    }]);